.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.counterapp {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10rem;
}
.counterapp .count {
  margin-bottom: 2rem;
  text-align: center;
  display: flex;
  margin-left: 10rem;
}
.counterapp .count h3 {
  line-height: 8.6rem;
  margin-right: 1rem;
}
.counterapp .count h1 {
  font-size:5em;
  margin-top: 2rem;
}
.counterapp .buttons button {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  border: none;
  margin: 0 2rem;
  font-size: 5em;
  box-shadow: 0 4px 32px 0 rgba(10,14,29,.02), 0 8px 64px 0 rgba(10,14,29,.08);
  cursor: pointer;
}
.counterapp .buttons button:hover{
  font-size: 7em;
}
.counterapp .buttons button:focus{
  outline: 0;
}
.addcounter input{
    width: 100px;
    height: 40px;
    text-align: center;
    border: 1px solid #2a2742;
    border-radius: 10px;
    outline: none;
    font-size: 2rem;
    font-weight: 400;
    padding: 5px;  
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.width-40 {
  width: 40px;
}
.addbtn{
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: none;
  margin: 0 2rem;
  font-size: 2em;
  box-shadow: 0 4px 32px 0 rgba(10,14,29,.02), 0 8px 64px 0 rgba(10,14,29,.08);
  cursor: pointer;
}